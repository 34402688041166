import React from 'react'
import logo from "../../Component/Accets/logo.png";

import {BsDiscord,BsTelegram} from "react-icons/bs"
import {FaTwitter} from "react-icons/fa"

export default function Footer_up() {
  return (
    <div className='text-white main_clr'>
  <div className="container">

  <div className="row justify-content-evenly    ">
    <div className="col-md-4 res_pon_ali">
        <img src={logo } className="footer_logo"  alt="" />
    </div>
    <div className="col-md-4 res_pon_ali  ">
    <div>        <h5>
Quick Links</h5>
<a href="https://bether.info/" className='footer_links'>
    <p>Home</p>
    
</a>
<a href="" className='footer_links'>
    <p></p>
    
</a>
<a href="" className='footer_links'>
    <p></p>
    
</a>
<a href="" className='footer_links'>
    <p></p>
    
</a>
</div>

    </div>
    <div className="col-md-4  res_pon_ali  ">
  <div>
        <h5>Contact Us</h5>
        <div className="conta d-flex ">
           <p>info@guardianswap.finance </p>    
        </div>

        </div>
    </div>
    </div>
  </div>

</div>
  )
}
