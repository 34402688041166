import React from "react";
import "./Updated.css";
import up from "../Accets/up.png";
import { FaWallet } from "react-icons/fa";
import { AiFillClockCircle } from "react-icons/ai";
import { BsFillKeyboardFill } from "react-icons/bs";
import { BsCheck2All } from "react-icons/bs";
import { FaMousePointer } from "react-icons/fa";

export default function Updated() {
    return (
        <div className="upadted py-5">
            <div className="text-center">
                <h3 className="htu text-center text-white"></h3>

                <div className="container py-5">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-6">
                            <img src={up} alt="" className="res_size" />
                        </div>
                        <div className="col-md-6">
                            <div className="up_box">
                                <p className="mb-0 p-0">
                                    <FaWallet className="icon_fs"></FaWallet>
                                </p>
                                <h5> Connect your wallet </h5>
                            </div>
                            <div className="up_box">
                                <p className="mb-0 p-0">
                                    <BsFillKeyboardFill className="icon_fs"></BsFillKeyboardFill>
                                </p>
                                <h5> Input the amount of USDT to stake </h5>
                            </div>
                            <div className="up_box">
                                <p className="mb-0 p-0">
                                    <AiFillClockCircle className="icon_fs"></AiFillClockCircle>
                                </p>
                                <h5> Select 'LOCK' Period </h5>
                            </div>
                            <div className="up_box">
                                <p className="mb-0 p-0">
                                    <FaMousePointer className="icon_fs"></FaMousePointer>
                                </p>
                                <h5> Select 'STAKE' button </h5>
                            </div>
                            <div className="up_box">
                                <p className="mb-0 p-0">
                                    <BsCheck2All className="icon_fs"></BsCheck2All>
                                </p>
                                <h5> Confirm transaction in your wallet </h5>
                            </div>
                        </div>
                    </div>

                    {/* Common Header for Comments */}

                    <div
                        className="comments-header mt-4"
                        style={{ backgroundColor: "#808080", color: "black", padding: "10px", borderRadius: "5px" }}
                    >
                        <h2 className="text-black">User Comments</h2>
                    </div>
                    {/* Container for Comments */}
                    {/* --------------------------------------01----------------------------------------- */}
                    <div className="comments-container text-center mt-4">
                        <div className="up_box">
                            <p className="mb-0 p-0">{/* <BsCheck2All className='icon_fs'></BsCheck2All> */}</p>
                            <p style={{ fontStyle: "", color: "white", marginTop: 0, textAlign: "left" }}>
                                <h4>
                                    <strong>
                                        {" "}
                                        "Staking USDT has been a game-changer! Amazing returns – my investment has grown
                                        significantly."
                                    </strong>
                                </h4>
                                <h6>
                                    --Aarav Patel
                                    <br />
                                </h6>
                            </p>
                        </div>
                    </div>
                    {/* --------------------------------------02----------------------------------------- */}
                    <div className="comments-container text-center mt-4">
                        <div className="up_box">
                            <p className="mb-0 p-0">{/* <BsCheck2All className='icon_fs'></BsCheck2All> */}</p>
                            <p style={{ fontStyle: "", color: "white", marginTop: 0, textAlign: "left" }}>
                                <h4>
                                    <strong>
                                        {" "}
                                        "Impressive returns from staking USDT! The stability and profitability make it a
                                        standout choice."
                                    </strong>
                                </h4>
                                <h6>
                                    --Akshita Sharma
                                    <br />
                                </h6>
                            </p>
                        </div>
                    </div>
                    {/* ---------------------------------------03---------------------------------------- */}
                    <div className="comments-container text-center mt-4">
                        <div className="up_box">
                            <p className="mb-0 p-0">{/* <BsCheck2All className='icon_fs'></BsCheck2All> */}</p>
                            <p style={{ fontStyle: "", color: "white", marginTop: 0, textAlign: "left" }}>
                                <h4>
                                    <strong>
                                        {" "}
                                        "Staking USDT has proven to be a lucrative move for me. The returns are
                                        fantastic, and the process is seamless."
                                    </strong>
                                </h4>
                                <h6>
                                    --Ethan Thompson
                                    <br />
                                </h6>
                            </p>
                        </div>
                    </div>
                    {/* ----------------------------------------04--------------------------------------- */}
                    <div className="comments-container text-center mt-4">
                        <div className="up_box">
                            <p className="mb-0 p-0">{/* <BsCheck2All className='icon_fs'></BsCheck2All> */}</p>
                            <p style={{ fontStyle: "", color: "white", marginTop: 0, textAlign: "left" }}>
                                <h4>
                                    <strong>
                                        {" "}
                                        "Incredible returns with USDT staking! I'm consistently earning, and it's been a
                                        reliable source of passive income."
                                    </strong>
                                </h4>
                                <h6>
                                    --Olivia Johnson
                                    <br />
                                </h6>
                            </p>
                        </div>
                    </div>
                    {/* ------------------------------------------05------------------------------------- */}
                    <div className="comments-container text-center mt-4">
                        <div className="up_box">
                            <p className="mb-0 p-0">{/* <BsCheck2All className='icon_fs'></BsCheck2All> */}</p>
                            <p style={{ fontStyle: "", color: "white", marginTop: 0, textAlign: "left" }}>
                                <h4>
                                    <strong>
                                        {" "}
                                        "Staking USDT is a winner! The returns are fantastic, and it's a secure way to
                                        grow your investment."
                                    </strong>
                                </h4>
                                <h6>
                                    --Zayd Al-Maktoum
                                    <br />
                                </h6>
                            </p>
                        </div>
                    </div>
                    {/* -------------------------------------------06------------------------------------ */}
                    <div className="comments-container text-center mt-4">
                        <div className="up_box">
                            <p className="mb-0 p-0">{/* <BsCheck2All className='icon_fs'></BsCheck2All> */}</p>
                            <p style={{ fontStyle: "", color: "white", marginTop: 0, textAlign: "left" }}>
                                <h4>
                                    <strong>
                                        {" "}
                                        "I've had a fantastic experience staking USDT. The returns have exceeded my
                                        expectations, and I'm thrilled with the results."
                                    </strong>
                                </h4>
                                <h6>
                                    --Aisha Khalifa
                                    <br />
                                </h6>
                            </p>
                        </div>
                    </div>
                    {/* -------------------------------------------07------------------------------------ */}
                    <div className="comments-container text-center mt-4">
                        <div className="up_box">
                            <p className="mb-0 p-0">{/* <BsCheck2All className='icon_fs'></BsCheck2All> */}</p>
                            <p style={{ fontStyle: "", color: "white", marginTop: 0, textAlign: "left" }}>
                                <h4>
                                    <strong>
                                        {" "}
                                        "Staking USDT has been a smart financial move. The returns are consistently
                                        fantastic – definitely worth exploring."
                                    </strong>
                                </h4>
                                <h6>
                                    --James Wilson
                                    <br />
                                </h6>
                            </p>
                        </div>
                    </div>
                    {/* -------------------------------------------08------------------------------------ */}
                    <div className="comments-container text-center mt-4">
                        <div className="up_box">
                            <p className="mb-0 p-0">{/* <BsCheck2All className='icon_fs'></BsCheck2All> */}</p>
                            <p style={{ fontStyle: "", color: "white", marginTop: 0, textAlign: "left" }}>
                                <h4>
                                    <strong>
                                        {" "}
                                        "Getting fantastic returns through USDT staking! It's a reliable and rewarding
                                        investment strategy that I highly recommend."
                                    </strong>
                                </h4>
                                <h6>
                                    --Emily Turner
                                    <br />
                                </h6>
                            </p>
                        </div>
                    </div>
                    {/* -------------------------------------------09------------------------------------ */}
                    <div className="comments-container text-center mt-4">
                        <div className="up_box">
                            <p className="mb-0 p-0">{/* <BsCheck2All className='icon_fs'></BsCheck2All> */}</p>
                            <p style={{ fontStyle: "", color: "white", marginTop: 0, textAlign: "left" }}>
                                <h4>
                                    <strong>
                                        {" "}
                                        "Staking USDT has been a financial game-changer for me – fantastic returns that
                                        exceeded my expectations. Highly recommend exploring this lucrative
                                        opportunity!"
                                    </strong>
                                </h4>
                                <h6>
                                    --Oliver Hughes
                                    <br />
                                </h6>
                            </p>
                        </div>
                    </div>
                    {/* -------------------------------------------10------------------------------------ */}
                    <div className="comments-container text-center mt-4">
                        <div className="up_box">
                            <p className="mb-0 p-0">{/* <BsCheck2All className='icon_fs'></BsCheck2All> */}</p>
                            <p style={{ fontStyle: "", color: "white", marginTop: 0, textAlign: "left" }}>
                                <h4>
                                    <strong>
                                        {" "}
                                        "Thrilled with the fantastic returns I've gained from staking USDT. It's proven
                                        to be a reliable and rewarding investment strategy. Definitely a smart move for
                                        those seeking stable growth."
                                    </strong>
                                </h4>
                                <h6>
                                    --Sunil Gupta
                                    <br />
                                </h6>
                            </p>
                        </div>
                    </div>
                    {/* ------------------------------------------------------------------------------- */}
                </div>
            </div>
        </div>
    );
}
