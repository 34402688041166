import React from "react";
import "./Flexible";
import Flextabs from "../Flextabs/Flextabs";

function Flexible() {
  return (
    <div className="">
      <Flextabs />
    </div>
  );
}

export default Flexible;
