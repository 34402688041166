import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import moment from "moment/moment";
import { Button } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { ARBStaking, ARBStaking_Abi, ARBtoken, ARBtoken_Abi } from "../../utilies/constant";
import Web3 from "web3";
import { toast } from "react-toastify";
import Connent from "../Connent/Connent";
import "./mylockStyle.css";
import { ethers } from "ethers";

export default function Mylock({ setShoww, check }) {
    let { provider, acc, providerType, web3 } = useSelector((state) => state.connectWallet);
    const [UserInformationStak, setUserInformationStak] = useState([]);
    const [spinner, setspinner] = useState(false);

    const checkBalance = async () => {
        try {
            // const webSupply = new Web3("https://bsc-dataseed.binance.org");
            let stakingContractOf;

            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();

            // old
            // stakingContractOf = new webSupply.eth.Contract(ARBStaking_Abi, ARBStaking);
            // new
            stakingContractOf = new ethers.Contract(ARBStaking, ARBStaking_Abi, signer);
            if (acc != null) {
                // old
                // let UserInformation = await stakingContractOf.methods.userInformation(acc).call();
                let UserInformation = await stakingContractOf.userInformation(acc);

                // console.log("UserInformation", UserInformation);

                let array1 = UserInformation[0];
                let array2 = UserInformation[1];
                let array3 = UserInformation[2];
                let myArray = [];
                let currentTime = Math.floor(new Date().getTime() / 1000.0);
                // console.log("Data", new Date(1673520674));
                for (let i = 0; i < array1.length; i++) {
                    // let date =new Date(Number(array3[i])*1000).toUTCString();
                    let currentTimestamp = array3[i];
                    // console.log("Type", Number(currentTimestamp) + Number(60) * array2[i]);
                    let date = moment(Number(array3[i]) * 1000).format("DD-MM-YYYY");
                    let obj = {
                        address: acc,
                        amount: web3.utils.fromWei(array1[i]),
                        unLoackTime: Number(currentTimestamp) + Number(86400) * array2[i],
                        LockTime: date,
                    };
                    myArray = [...myArray, obj];
                }

                setUserInformationStak(myArray);
            }
        } catch (error) {
            console.log(error);
        }
    };

    // const checkDetails = async () => {
    //     const provider = new ethers.providers.Web3Provider(window.ethereum);
    //     const signer = provider.getSigner();

    //     const stakingContractOf = new ethers.Contract(ARBStaking, ARBStaking_Abi, signer);
    //     if (acc != null) {
    //         // new
    //         const UserInformation = await stakingContractOf.userInformation(acc);
    //         setUserInformationStak(UserInformation);
    //         // console.log("Data", new Date(1673520674));
    //         // for (let i = 0; i < array1.length; i++) {
    //         //     let currentTimestamp = array3[i];
    //         //     let date = moment(Number(array3[i]) * 1000).format("DD-MM-YYYY");
    //         //     let obj = {
    //         //         address: acc,
    //         //         amount: web3.utils.fromWei(array1[i]),
    //         //         unLoackTime: Number(currentTimestamp) + Number(86400) * array2[i],
    //         //         LockTime: date,
    //         //     };
    //         // }
    //     }
    // };

    useEffect(() => {
        checkBalance();
        // checkDetails();
        // eslint-disable-next-line
    }, []);

    const Completionist = () => {
        return (
            <>
                <div className="text_days fs-5 ">Unstaked Time Reached!</div>
            </>
        );
    };
    // Renderer callback with condition
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <Completionist />;
        } else {
            return (
                <div className="text_days fs-5 ">
                    {/* {days} D {hours} H {minutes} M {seconds} S */}
                    {days}d : {hours}h : {minutes}m : {seconds}s
                </div>
            );
        }
    };

    // console.log("timecompleted",timecompleted);
    const confirm = (index) => {
        // Modal.confirm({
        //     title: "Confirm",
        //     icon: <ExclamationCircleOutlined />,
        //     content:
        //         "Before unstake time 10% will be deducted your amount and reward",
        //     okText: "Continue",
        //     cancelText: "Cancel",
        //     onOk: () => unstake(index),
        // })
    };

    const unstake = async (index) => {
        try {
            setspinner(true);
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const gasPrice = await signer.getGasPrice();

            let stakingContractOf;
            let tokenContractOf;
            tokenContractOf = new ethers.Contract(ARBtoken, ARBtoken_Abi, signer);

            const getbal = await tokenContractOf.balanceOf(ARBStaking);

            if (Number(Number(getbal._hex) >= UserInformationStak[0].amount)) {
                // stakingContractOf = new web3.eth.Contract(ARBStaking_Abi, ARBStaking);
                stakingContractOf = new ethers.Contract(ARBStaking, ARBStaking_Abi, signer);
                // await stakingContractOf.methods.harvest([index]).send({
                //     from: acc,
                // });
                await stakingContractOf.harvest([index], {
                    gasPrice: gasPrice,
                    gasLimit: "21000",
                });
                toast.success("Transaction Confirmed");
                setspinner(false);
            } else {
                toast.error("Insufficent Balance");
                setspinner(false);
            }
            setspinner(false);
        } catch (e) {
            console.log("Error while calling Unstaking function", e);
            setspinner(false);
        }
    };

    return (
        <div>
            <div className="container-fluid p-0">
                <>
                    <div className="">
                        <table class="table mt-5 text-white h-100 ">
                            <thead>
                                <tr>
                                    <th scope="col">Address</th>
                                    <th scope="col">Staked Amount</th>
                                    <th scope="col">Staked Time</th>
                                    <th scope="col">Remaining Time to Unstaked </th>
                                    <th scope="col">Unstaked</th>
                                </tr>
                            </thead>
                            <tbody className="text-white ">
                                {UserInformationStak?.map((items, index) => {
                                    console.log("unloacktime", index);
                                    return (
                                        <>
                                            {items.amount === 0 ? (
                                                <></>
                                            ) : (
                                                <>
                                                    <tr>
                                                        <th scope="row">
                                                            {acc?.substring(0, 4) +
                                                                "..." +
                                                                acc?.substring(acc?.length - 4)}
                                                        </th>
                                                        <td>{items.unLoackTime} </td>
                                                        <td>{items.LockTime}</td>
                                                        <td>
                                                            {" "}
                                                            <Countdown
                                                                date={
                                                                    Date.now() +
                                                                    (parseInt(items.unLoackTime) * 1000 - Date.now())
                                                                }
                                                                renderer={renderer}
                                                            />
                                                        </td>

                                                        <td>
                                                            <Button
                                                                onClick={() =>
                                                                    parseInt(items.unLoackTime) >=
                                                                    parseInt(Date.now() / 1000)
                                                                        ? confirm(index)
                                                                        : unstake(index)
                                                                }
                                                                // onClick={() => timecompleted==false ? unstake(index):confirm(index)}
                                                                className="unlockBTN text-white"
                                                                disabled={
                                                                    parseInt(items.unLoackTime) >=
                                                                    parseInt(Date.now() / 1000)
                                                                        ? true
                                                                        : false
                                                                }
                                                            >
                                                                UnStake
                                                            </Button>
                                                        </td>
                                                        {/* <td><button className="btn btn-success unlockBTN" title="Tooltip on top">UnLock</button></td> */}
                                                    </tr>
                                                </>
                                            )}
                                        </>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </>
            </div>
        </div>
    );
}
