import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Web3 from "web3";
import { ARBStaking, ARBStaking_Abi, ARBtoken, ARBtoken_Abi } from "../../utilies/constant";
import Connent from "../Connent/Connent";
import "./Lockestake.css";
import Countdown from "react-countdown";
import moment from "moment/moment";
import { Button, Popover } from "antd";
import { Modal, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import CopyToClipboard from "react-copy-to-clipboard";
import { ethers } from "ethers";
import { useSearchParams } from "react-router-dom";

function Lockestake({ setShoww, check }) {
    let { provider, acc, providerType, web3 } = useSelector((state) => state.connectWallet);
    const [selectDays, setselectDays] = useState(0);
    const [selectpercent, setpercent] = useState(0);

    const [getValue, setgetValue] = useState(0);
    const [Active, setActive] = useState(0);
    const [spinner, setspinner] = useState(false);
    const [balance, setbalance] = useState(0);
    const [selectedCard, setselectedCard] = useState(null);
    const [cradShow, setcradShow] = useState([]);
    const [stakeddata, setstakeddata] = useState();
    const [cardIndex, setcardIndex] = useState([]);
    const [slectedAllnfton, setslectedAllnfton] = useState({
        condition: false,
        walletOfOwneron: [],
    });
    const [noSelectedAll, setnoSelectedAll] = useState([]);
    const [refAddress, setRefAddress] = useState("");
    const [refAddressfun, setRefAddressfun] = useState("");
    const [TotalNft_Stake_Check, setTotalNft_Stake_Check] = useState(0);
    const [thirtydaylimit, setthirtydaylimit] = useState(0);
    const [copied, setCopied] = useState(false);
    const [MaxAmount, setMaxAmount] = useState(0);

    const [searchParams, setSearchParams] = useSearchParams();
    const query = searchParams.get("ref");

    const staking_Amount = async () => {
        try {
            if (selectDays === 1) {
                toast.error("Please Select Days");
                setspinner(false);
            } else {
                if (getValue == null) {
                    toast.error("Please Enter Amount First!");
                    setspinner(false);
                } else {
                    if (getValue < 1) {
                        toast.error("Minimum Staking Amount 1!");
                        setspinner(false);
                    } else {
                        if (acc == null) {
                            toast.error("Please Connect Metamaske First!");
                            setShoww(true);
                            setspinner(false);
                        } else {
                            if (selectpercent > getValue) {
                                toast.error(`Minimum Token ${selectpercent}`);
                                setspinner(false);
                            } else {
                                if (MaxAmount < getValue) {
                                    toast.error(`Maximum Token ${MaxAmount}`);
                                    setspinner(false);
                                } else {
                                    setspinner(true);
                                    let stakingContractOf;
                                    let tokenContractOf;

                                    const provider = new ethers.providers.Web3Provider(window.ethereum);
                                    const signer = provider.getSigner();
                                    const gasPrice = await signer.getGasPrice();

                                    // stakingContractOf = new web3.eth.Contract(ARBStaking_Abi, ARBStaking);
                                    stakingContractOf = new ethers.Contract(ARBStaking, ARBStaking_Abi, signer);
                                    // tokenContractOf = new web3.eth.Contract(ARBtoken_Abi, ARBtoken);
                                    tokenContractOf = new ethers.Contract(ARBtoken, ARBtoken_Abi, signer);

                                    let stakingValue = web3.utils.toWei(getValue.toString());
                                    // let checkstakingValue = web3.utils.toWei(getValue);
                                    let checkbalance = web3.utils.toWei(balance.toString());

                                    if (Number(checkbalance) >= Number(stakingValue)) {
                                        let UserID;
                                        if (window.location.href.includes("ref")) {
                                            UserID = window.location.href.split("=");
                                            UserID = UserID[UserID.length - 1];
                                            console.log("refferal", UserID);
                                            setRefAddressfun(UserID);
                                        } else {
                                            UserID = "0x000000000000000000000000000000000000dEaD";
                                        }
                                        // alert(UserID)
                                        // let refferalStatus = await stakingContractOf.methods
                                        //     .refferalStatus(UserID)
                                        //     .call();

                                        console.log("refAddressfun", UserID);
                                        // if (
                                        //     UserID == "0x000000000000000000000000000000000000dEaD" ||
                                        //     refferalStatus == true
                                        // ) {

                                        // old web3 function
                                        // await tokenContractOf.methods
                                        //     .approve(ARBStaking, stakingValue.toString())
                                        //     .send({
                                        //         from: acc,
                                        //     });

                                        // latest ethers function
                                        await tokenContractOf.approve(ARBStaking, stakingValue.toString());

                                        toast.success("Approve Confirmed");

                                        // old web3 function
                                        // await stakingContractOf.methods.farm(selectDays, stakingValue.toString()).send({
                                        //     from: acc,
                                        // });

                                        // latest ethers function
                                        if (query) {
                                            await stakingContractOf.refer(query, selectDays, stakingValue.toString(), {
                                                gasPrice: gasPrice,
                                                gasLimit: "3000000",
                                            });
                                        } else {
                                            await stakingContractOf.farm(selectDays, stakingValue.toString(), {
                                                gasPrice: gasPrice,
                                                gasLimit: "3000000",
                                            });
                                        }
                                        toast.success("USDT Token Staked Successfull.");
                                        setspinner(false);
                                    } else {
                                        toast.error("Insufficient Balance");
                                        setspinner(false);
                                    }
                                    setspinner(false);
                                }
                                setspinner(false);
                            }
                        }
                    }
                }
            }
        } catch (e) {
            console.log("Error", e);
            setspinner(false);
        }
    };
    const checkBalance = async () => {
        try {
            // const webSupply = new Web3("https://bsc-dataseed.binance.org");
            // const webSupply = new Web3("https://1rpc.io/bnb");
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();

            // let tokenContractOf = new webSupply.eth.Contract(ARBtoken_Abi, ARBtoken);
            let tokenContractOf = new ethers.Contract(ARBtoken, ARBtoken_Abi, signer);

            if (acc != null) {
                let blanceOf = await tokenContractOf.balanceOf(acc);
                // console.log("blanceOf", blanceOf);
                // let blanceOf = await web3.eth.getBalance(acc);
                // blanceOf = web3.utils.fromWei(blanceOf);

                // blanceOf = blanceOf.slice(0, 12);
                // console.log("blanceOf", blanceOf);
                setbalance(Number(ethers?.utils?.formatEther(blanceOf)).toFixed(2));
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        checkBalance();
    });

    useEffect(() => {
        if (acc) {
            setRefAddress(`${window.location.origin}/?ref=${acc}`);
        } else {
            setRefAddress("Connect wallet");
        }
    }, [acc]);

    return (
        <>
            <div className="container-fluid p-0  mt-5">
                <div className="row justify-content-center">
                    <div className="col-lg-9 all_main p-0">
                        <h3 class="staking__selector__heading">Stake USDT TOKEN</h3>

                        <div className="first_box mt-4  px-2">
                            <div className="munt_box d-flex justify-content-between">
                                <span className="">Amount</span>
                                <p className="my_balnc ">
                                    <span> ~My balance:</span> <span>{balance} </span>
                                </p>
                            </div>
                            <div className="typ_area border ">
                                <div className="mx_buttn str_tp_dollar text-cenetr ">USDT TOKEN</div>
                                <input
                                    className="ariia"
                                    type="number"
                                    inputMode="decimal"
                                    placeholder="0"
                                    autoComplete="off"
                                    autoCorrect="off"
                                    aria-aria-valuemin="0"
                                    aria-valuemax="9007199254740991"
                                    onChange={(e) => setgetValue(e.target.value)}
                                    value={getValue}
                                />

                                <button
                                    type="button"
                                    className="mx_buttn text-white "
                                    onClick={() => setgetValue(balance)}
                                >
                                    Max
                                </button>
                            </div>
                        </div>

                        <div className="second_box mt-3 px-2">
                            <p className="text-start">Locking Time</p>
                            <div className="time_table">
                                <div className="dan_gtr text-white">
                                    <div
                                        className=" border des_tw p-0 "
                                        style={{
                                            background:
                                                Active === 1
                                                    ? "linear-gradient(98.76deg, rgb(56, 195, 207) 0%, rgb(135, 103, 211) 100%)"
                                                    : "rgb(24, 22, 82)",
                                        }}
                                    >
                                        <button
                                            className="btn btn-md dates"
                                            onClick={() => (
                                                setselectDays(7), setActive(1), setpercent(10), setMaxAmount(10000)
                                            )}
                                        >
                                            07 Days
                                        </button>
                                        <div className="arp border-top">77.25 % APY</div>
                                        <div className="arp border-top">Min. Stake $10</div>
                                    </div>
                                    <div
                                        className=" border des_tw p-0"
                                        style={{
                                            background:
                                                Active === 2
                                                    ? "linear-gradient(98.76deg, rgb(56, 195, 207) 0%, rgb(135, 103, 211) 100%)"
                                                    : "rgb(24, 22, 82)",
                                        }}
                                    >
                                        <button
                                            className="btn btn-md dates"
                                            onClick={() => (
                                                setselectDays(30), setActive(2), setpercent(50), setMaxAmount(100000)
                                            )}
                                        >
                                            30 Days
                                        </button>
                                        <div className="arp border-top">556.92 % APY</div>
                                        <div className="arp border-top">Min. Stake $100</div>
                                    </div>
                                    <div
                                        className=" border des_tw p-0"
                                        style={{
                                            background:
                                                Active === 3
                                                    ? "linear-gradient(98.76deg, rgb(56, 195, 207) 0%, rgb(135, 103, 211) 100%)"
                                                    : "rgb(24, 22, 82)",
                                        }}
                                    >
                                        <button
                                            className="btn btn-md dates"
                                            onClick={() => (
                                                setselectDays(60), setActive(3), setpercent(100), setMaxAmount(1000000)
                                            )}
                                        >
                                            60 Days
                                        </button>
                                        <div className="arp border-top">1280.84 % APY</div>
                                        <div className="arp border-top">Min. Stake $300</div>
                                    </div>
                                    <div
                                        className=" border des_tw p-0"
                                        style={{
                                            background:
                                                Active === 4
                                                    ? "linear-gradient(98.76deg, rgb(56, 195, 207) 0%, rgb(135, 103, 211) 100%)"
                                                    : "rgb(24, 22, 82)",
                                        }}
                                    >
                                        <button
                                            className="btn btn-md dates"
                                            onClick={() => (
                                                setselectDays(90), setActive(4), setpercent(100), setMaxAmount(100000)
                                            )}
                                        >
                                            90 Days
                                        </button>
                                        <div className="arp border-top">2305.51 % APY</div>
                                        <div className="arp border-top">Min. Stake $500</div>
                                    </div>
                                    <div
                                        className=" border des_tw p-0"
                                        style={{
                                            background:
                                                Active === 5
                                                    ? "linear-gradient(98.76deg, rgb(56, 195, 207) 0%, rgb(135, 103, 211) 100%)"
                                                    : "rgb(24, 22, 82)",
                                        }}
                                    >
                                        <button
                                            className="btn btn-md dates"
                                            onClick={() => (
                                                setselectDays(180), setActive(5), setpercent(250), setMaxAmount(1000000)
                                            )}
                                        >
                                            180 Days
                                        </button>
                                        <div className="arp border-top">5533.22 % APY</div>
                                        <div className="arp border-top">Min. Stake $800</div>
                                    </div>
                                    <div
                                        className=" border des_tw p-0"
                                        style={{
                                            background:
                                                Active === 6
                                                    ? "linear-gradient(98.76deg, rgb(56, 195, 207) 0%, rgb(135, 103, 211) 100%)"
                                                    : "rgb(24, 22, 82)",
                                        }}
                                    >
                                        <button
                                            className="btn btn-md dates"
                                            onClick={() => (
                                                setselectDays(365), setActive(6), setpercent(500), setMaxAmount(1000000)
                                            )}
                                        >
                                            365 Days
                                        </button>
                                        <div className="arp border-top">14025.17 % APY</div>
                                        <div className="arp border-top">Min. Stake $1500</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button className="btn btn-md lst_btnn mt-3 text-white" onClick={() => staking_Amount()}>
                            {spinner === true ? (
                                <>
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </>
                            ) : (
                                " Enable Staking"
                            )}
                        </button>

                        <div className="last mt-4">
                            <p className="fon m-0 py-2">
                                Locking {getValue} USDT TOKEN for {selectDays} Days
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-lg-6">
                    <div className="container-fluid p-0  mt-5">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 all_main p-0 pb-5">
                                <h3 class="staking__selector__heading">Refferal Address</h3>

                                <div className="second_box mt-3 px-2">
                                    <div class=" items-start">
                                        <p class="text-15 text-black">Your Referral Link</p>
                                        <input
                                            placeholder="Referral Addrses"
                                            type="text"
                                            class="ariia"
                                            disabled
                                            value={refAddress}
                                        />
                                    </div>
                                </div>
                                <CopyToClipboard
                                    text={refAddress}
                                    onCopy={() => {
                                        setCopied(true);
                                        setTimeout(() => {
                                            setCopied(false);
                                        }, 2000);
                                    }}
                                >
                                    <button type="button" className="btn btn-md lst_btnn mt-3 text-white">
                                        {copied ? "Copied!" : "Copy Referral"}
                                    </button>
                                </CopyToClipboard>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Lockestake;
