import Web3 from "web3";
let isItConnected = false;
const networks = {
    bsc: {
        //  testnet chain id 97
        //  mainnet chain id 56
        chainId: `0x${Number(56).toString(16)}`, // 56
        chainName: "Binance smart chain",
        nativeCurrency: {
            name: "BNB",
            symbol: "BNB",
            decimals: 18,
        },
        rpcUrls: ["https://bsc-dataseed.binance.org"],
        // rpcUrls: ["https://1rpc.io/bnb"],
        blockExplorerUrls: ["https://bscscan.com/"],
    },
};
const changeNetwork = async ({ networkName }) => {
    try {
        if (!window.ethereum) throw new Error("No crypto wallet found");
        await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
                {
                    ...networks[networkName],
                },
            ],
        });
    } catch (err) {
        console.log("not found");
    }
};
const handleNetworkSwitch = async (networkName) => {
    await changeNetwork({ networkName });
};
let accounts;
const getAccounts = async () => {
    const web3 = window.web3;
    try {
        accounts = await web3.eth.getAccounts();
        return accounts;
    } catch (error) {
        console.log("Error while fetching acounts: ", error);
        return null;
    }
};
export const disconnectWallet = async () => {
    await window.ethereum.request({
        method: "eth_requestAccounts",
        params: [{ eth_accounts: {} }],
    });
    console.log("disconnect");
};
export const loadWeb3 = async () => {
    try {
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum);
            await window.ethereum.enable();
            await window.web3.eth.getChainId((err, netId) => {
                console.log("networkId==>", netId);
                switch (netId.toString()) {
                    // change testnet chainid 97
                    // change mainnet chainid 56
                    case "56":
                        isItConnected = true;
                        break;
                    default:
                        handleNetworkSwitch("bsc");
                        isItConnected = false;
                }
            });
            if (isItConnected === true) {
                let accounts = await getAccounts();
                return accounts[0];
            } else {
                let res = "Wrong Network";
                return res;
            }
        } else {
            let res = "No Wallet";
            return res;
        }
    } catch (error) {
        let res = "No Wallet";
        return res;
    }
};
